import { COUNTRY_CODES } from './country-codes';

export const enum LOCALES {
    Greek = 'gr',
    English = 'en',
    France = 'fr',
    Dutch = 'nl',
    Portuguese = 'pt',
    Italian = 'it',
    Spanish = 'es',
    Polish = 'pl',
    Romanian = 'ro',
    Deutsch = 'de',
    Bulgarian = 'bg',
    Finnish = 'fi',
    Danish = 'da',
    Hungarian = 'hu',
    Croatian = 'hr',
    Czech = 'cs',
    Sweden = 'sv',
    Norwegian = 'nb'
}

export type Locale = {[key in LOCALES]?: {
    lang: string
}};

export const localeSettings = {
    [LOCALES.Greek]: {
        culture: 'el',
        dateFormat: 'el',
        nativeName: 'Ελληνικά'
    },
    [LOCALES.English]: {
        culture: 'en',
        dateFormat: 'en-gb',
        nativeName: 'English'
    },
    [LOCALES.France]: {
        culture: 'fr',
        dateFormat: 'fr',
        nativeName: 'Français'
    },
    [LOCALES.Dutch]: {
        culture: 'nl',
        dateFormat: 'nl',
        nativeName: 'Dutch'
    },
    [LOCALES.Portuguese]: {
        culture: 'pt',
        dateFormat: 'pt',
        validator: 'pt_PT',
        nativeName: 'Português'
    },
    [LOCALES.Italian]: {
        culture: 'it',
        dateFormat: 'it',
        nativeName: 'Italiano'
    },
    [LOCALES.Spanish]: {
        culture: 'es',
        dateFormat: 'es',
        nativeName: 'Español'
    },
    [LOCALES.Polish]: {
        culture: 'pl',
        dateFormat: 'pl',
        nativeName: 'Polski'
    },
    [LOCALES.Romanian]: {
        culture: 'ro',
        dateFormat: 'ro',
        nativeName: 'Română'
    },
    [LOCALES.Deutsch]: {
        culture: 'de',
        dateFormat: 'de',
        nativeName: 'Deutsch'
    },
    [LOCALES.Bulgarian]: {
        culture: 'bg',
        dateFormat: 'bg-fix',
        nativeName: 'Български'
    },
    [LOCALES.Finnish]: {
        culture: 'fi',
        dateFormat: 'fi',
        nativeName: 'Suomi'
    },
    [LOCALES.Danish]: {
        culture: 'da',
        dateFormat: 'da',
        nativeName: 'Dansk'
    },
    [LOCALES.Croatian]: {
        culture: 'hr',
        dateFormat: 'hr',
        nativeName: 'Hrvatski'
    },
    [LOCALES.Hungarian]: {
        culture: 'hu',
        dateFormat: 'hu',
        nativeName: 'Magyar'
    },
    [LOCALES.Czech]: {
        culture: 'cs',
        dateFormat: 'cs',
        nativeName: 'Česky'
    },
    [LOCALES.Sweden]: {
        culture: 'sv',
        dateFormat: 'sv',
        nativeName: 'Svenska'
    },
    [LOCALES.Norwegian]: {
        culture: 'nb',
        dateFormat: 'nb',
        validator: 'nb_NO',
        nativeName: 'Norsk'
    }
};

export const localeAlias = {
    el: LOCALES.Greek
};

export const defaultLang = LOCALES.English;

export const localizeOptions = {
    lang: defaultLang,
    culture: localeSettings[defaultLang].culture,
    path: 'locales/{{lng}}/{{ns}}.json'
};

export const layoutOptions = {
    prefix: 'layout',
    default: 'default'
};

export const NavigationStateOptions = {
    vuex: 'vuex-navigation',
    navigationIndex: 1,
    navigationState: 'vuex-navigation',
    duration: {
        day: 100
    }
};

export enum SourceOfFunds {
    Other = 0,
    Salary = 1,
    Loan = 2,
    Inheritance = 3,
    RealEstateRentOrSale = 4,
    Pension = 5
}

export const SourceOfFundsOrder: Array<SourceOfFunds> = [
    SourceOfFunds.Salary,
    SourceOfFunds.Loan,
    SourceOfFunds.Inheritance,
    SourceOfFunds.RealEstateRentOrSale,
    SourceOfFunds.Pension,
    SourceOfFunds.Other
];

export enum MerchantSourceOfFunds {
    Other = 0,
    Sales = 1,
    Loan = 2,
    Capital = 3,
    Donation = 4
}

export const MerchantSourceOfFundsOrder: Array<MerchantSourceOfFunds> = [
    MerchantSourceOfFunds.Sales,
    MerchantSourceOfFunds.Loan,
    MerchantSourceOfFunds.Capital,
    MerchantSourceOfFunds.Donation,
    MerchantSourceOfFunds.Other
];

export const MerchantCategoryAlias: Array<number> = [
    10008,
    20003,
    120067
];

export const MerchantCbdLabs: Array<number> = [
    10008,
    20003,
    120067
];

export const MerchantLabs: Array<number> = [
    ...MerchantCbdLabs
];

export const CategoryIdCountryBlacklist: { [key: string]: Array<number> } = {
    [COUNTRY_CODES.Spain]: MerchantCbdLabs,
    [COUNTRY_CODES.Malta]: MerchantCbdLabs,
    [COUNTRY_CODES.Finland]: MerchantCbdLabs,
    [COUNTRY_CODES.Bulgarian]: MerchantCbdLabs
};

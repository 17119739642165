import i18next, { InitOptions } from 'i18next';
import Http from 'i18next-http-backend';
import { Culture } from '@/core/i18n/store/models';

interface CulturePlugin {
    getCulture: Function,
    getLang: Function,
    setLang(lang: string, manuallyEdited?: boolean): void,
    getSettings(lang: string)
}

export interface CulturePluginOptions {
    id: string | number,
    defaultLang: string,
    i18nextOptions: InitOptions,
    localeSettings: any,
    localeAlias?: any
}

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $culture: CulturePlugin
    }
}

export default class VueCulture {
    static install(Vue, options: CulturePluginOptions) {
        Vue.$culture = {
            lang: null,
            id: options.id,
            i18nextOptions: options.i18nextOptions,
            localeSettings: options.localeSettings,
            localeAlias: options.localeAlias,

            getSettings(lang: string) {
                return this.localeSettings[lang] || this.localeSettings[options.defaultLang];
            },

            filterLang(lang: string) {
                lang = lang.toLowerCase();

                if (this.localeSettings[lang]) {
                    return lang;
                }

                if (this.localeAlias && this.localeAlias[lang]) {
                    return this.localeAlias[lang];
                }

                return options.defaultLang;
            },

            getCulture() {
                return Culture.find(this.id);
            },

            getLang() {
                const culture = this.getCulture();

                return culture ? this.filterLang(culture.lang) : options.defaultLang;
            },

            async setLang(lang: string, manuallyEdited?: boolean) {
                if (this.lang && this.lang === lang) {
                    return;
                }

                this.lang = this.filterLang(lang);

                const settings = this.getSettings(this.lang);

                this.i18nextOptions.lng = settings.culture;

                let cultureData: any = {
                    id: this.id,
                    lang: this.lang,
                    dateFormat: settings.dateFormat
                };

                if (typeof manuallyEdited !== 'undefined') {
                    cultureData = {
                        ...cultureData,
                        manuallyEdited
                    };
                }

                await i18next
                    .use(Http)
                    .init(this.i18nextOptions, async () => {
                        await Culture.insertOrUpdate({
                            data: cultureData
                        });
                    });
            }
        };
    }
}

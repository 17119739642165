import { Model } from '@vuex-orm/core';

export class Culture extends Model {
    static entity = 'culture';

    static fields() {
        return {
            id: this.attr(null),
            lang: this.string(''),
            dateFormat: this.string(''),
            manuallyEdited: this.attr(false) // True when user has select language through UI
        };
    }
}

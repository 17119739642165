export function getCultureDetails(culture: string, separator: string = '-') {
    const pattern = `(^([a-z]{2})${separator}([A-Z]{2})$)`;
    const regex = new RegExp(pattern);

    const cultureMatch = culture?.match(regex);

    if (!cultureMatch?.length) {
        return null;
    }

    return {
        language: cultureMatch[2],
        country: cultureMatch[3]
    };
}
